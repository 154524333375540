import React, { Component } from "react"
// import { Link, navigate } from 'gatsby'
import Header from "./header"
import { Collapse } from "antd"
import FaqBanner from "../../images/faq-banner.jpg"

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
class Faqpage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Header />
        <section className="aboutus__section faq__section">
          <img src={FaqBanner} alt="" />
          <div className="abouttext">
            <h1>Frequently Asked Questions</h1>
            <div className="whitebox">
              <h3>Frequently Asked Questions</h3>
              <Collapse
                accordion
                bordered={false}
                showArrow={false}
                className="faq-list"
              >
                <Panel
                  header="Can you deliver my container at a specific time?"
                  key="1"
                >
                  <p className="faqpad">
                    We will definitely do the best we can! The schedule is
                    routed daily and always changing. We always take all
                    requests into consideration but sometimes we aren’t always
                    able to arrive at the exact time. We will always notify
                    you of when you can expect your debris removal service.
                    </p>
                </Panel>
                <Panel
                  header="I’m looking to put heavy material in a 20 or 30 yard dumpster, is that possible?"
                  key="2"
                >
                  <p className="faqpad">
                    We aren’t able to do 20 or 30 yard containers with dirt,
                    rock, or sand as it is illegal to carry those sized
                    containers at those weights.
                  </p>
                </Panel>
                <Panel
                  header="Can I put a mix of materials in the container?"
                  key="4"
                >
                  <p className="faqpad">Yes you can, but best to check. Our experts will consult you and based on the materials you are removing will suggest the most cost effective and sustainable plan of attack.</p>
                </Panel>

                <Panel
                  header="How do street permits work?"
                  key="5"
                >
                  <p className="faqpad">When ordering a container that will go on any street within any of the 5 boroughs, it is required that we have a permit issued by the DOT. The permit will cost the client $50 plus tax and is valid for up to 5 days (including weekends).</p>
                </Panel>

                <Panel
                  header="I need my container delivered today! Can you do it?"
                  key="6"
                >
                  <p className="faqpad">We do the best we can to provide the highest level of service but it's not always easy to guarantee same day service. Give us a call and our experts will let you know how the schedule is looking and try our best to accommodate you.</p>
                </Panel>


                <Panel
                  header="Can I get a delivery on Saturday?"
                  key="7"
                >
                  <p className="faqpad">We do offer Saturday service dependent on scheduling.</p>
                </Panel>

                <Panel
                  header="What happens in the case of a missed delivery?"
                  key="8"
                >
                  <p className="faqpad">Our goal is to limit missed deliveries, but unfortunately things happen. We do have a missed delivery fee of $200 so best to avoid it whenever possible!</p>
                </Panel>

                <Panel
                  header="What are our hours of operation/when can I place orders?"
                  key="9"
                >
                  <p className="faqpad">The office is open Monday-Friday from 8am-5pm and Saturday from 8am-1pm. All orders must be placed in these windows of operation. You can call us at <a href="tel:7183846357">718-384-6357 </a> or email us at <a href="mailto:hello@mycurbside.com"> hello@mycurbside.com</a>.

                  </p>
                </Panel>

                <Panel
                  header="Will the driver wait for us to load the container?"
                  key="10"
                >
                  <p className="faqpad">The driver will not wait unless previously specified. We consider this a live load and it is priced and routed separately. Check out the website to learn more!</p>
                </Panel>

                <Panel
                  header="What happens if my container is overfilled?"
                  key="11"
                >
                  <p className="faqpad">Containers are to be filled level with the top, with no debris sticking out of the top or sides of the container. Any overload will be charged to the client’s account and they will be notified of those charges. Overloading containers is extremely dangerous, so best not to. We take safety very seriously and we want you to do the same.</p>
                </Panel>

                <Panel
                  header="How much space do you need for accessibility for exchanges and removals?"
                  key="12"
                >
                  <p className="faqpad">In order to exchange or remove a container, we need at least 8-10 feet collectively either in the front or the back of the container. Think approximately one car length of space.</p>
                </Panel>

                <Panel
                  header="Are there any materials that are not allowed in the container?"
                  key="13"
                >
                  <p className="faqpad">No 55 gallon drums empty or full, car batteries, asbestos or asbestos containing products, hazardous materials (liquids such as paint, oil, solvent, pesticides, etc.), CFC containing appliances (such as air conditioners, refrigerators, freezers, de-humidifiers, etc.), and no car tires are allowed.</p>
                </Panel>

                <Panel
                  header="How long can I keep the container for?"
                  key="14"
                >
                  <p className="faqpad">You can keep your container as long as you need it. We do, however, have a surcharge after 30 days. If you think you will need it on site for a long period of time, let our experts know and they will come up with the best solution for you.</p>
                </Panel>
              </Collapse>
            </div>
          </div>

        </section>


      </div>
    );
  }
}

export default Faqpage;
